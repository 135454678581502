.swal2-container .swal2-modal .swal2-actions .btn{
  margin: 0 5px;
}
.swal2-title{
  color: $dark-blue !important;
}
.swal2-html-container{
  color: $dark-blue !important;
}
.swal2-cancel{
  width: 181px !important;
  height: 43px !important;
  border-radius: 10px !important;
}
.swal2-confirm{
  width: 181px !important;
  height: 43px !important;
  border-radius: 10px !important;
}