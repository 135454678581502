//Mixin para cortar o texto após x linhas
@mixin ellipsis($lines) {
  overflow: hidden;
  text-overflow: ellipsis !important;
  /* autoprefixer: off */
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: $lines !important;
}

//mixins
@mixin base_text($size, $weight, $color, $text-align: left, $line-height: 1) {
  font-family: 'Open Sans', serif !important;
  font-size: $size !important;
  font-weight: $weight !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: $line-height !important;
  letter-spacing: normal !important;
  text-align: $text-align !important;
  color: $color !important;
}

// Mixins para validar responsividade
@mixin for-size($size) {
  // Para tablets e pequenos desktops e desktops
  @if $size == tablet-portrait-landscape-desktop {
    @media (min-width: 600px) and (max-width: 1279px) {
      @content;
    }
  }
}

// Mixins para validar o estilo do scrool
@mixin scrool-blue() {
  ::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    left: -5px;
  }

  ::-webkit-scrollbar-thumb {
    background: $avva-darkest-blue;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: $greyish;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
}

//CLASSES MODAL CONFIGURAÇÃO DE ALARMES
.section-scss {
  &.alarm-checkbox {
    min-height: 70px;
    border: 0.5px solid #d4d4d4;
    border-radius: 10px;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.19);
    @include ellipsis(2);
    &.checked {
      border-color: $blue;
    }
  }
  margin-top: 3%;
  &.big-gap {
    margin-top: 12%;
  }
  margin-bottom: 3%;
  .checkbox-footer {
    @include base_text(12px, normal, $greyish, $line-height: 1.08);
    padding-right: 4%;
    white-space: initial;
    @include for-size(desktop-medium) {
      width: calc(100vw * 0.4 - 67px);
    }
    @include for-size(tablet-portrait-landscape-desktop) {
      width: calc(100vw * 0.7 - 67px);
    }
  }
  mat-checkbox {
    position: relative;
    left: 25px;
    top: 20px;
    .mat-checkbox-frame {
      width: 20px !important;
      height: 20px !important;
      border-radius: 3px !important;
      border-width: 1px !important;
    }
    .mat-checkbox-label {
      margin-left: 2% !important;
      @include base_text(18px, normal, #757575, $line-height: 1.22);
    }
    .mat-checkbox-inner-container {
      height: 20px;
      width: 20px;
      margin-bottom: 3%;
    }
    .mat-checkbox-layout {
      margin-bottom: 5%;
    }

    &.mat-checkbox-checked {
      .mat-checkbox-frame {
        border-color: $dark-blue !important;
      }
      .mat-checkbox-label {
        margin-left: 2%;
        @include base_text(18px, normal, $avva-darkest-blue, $line-height: 1.22);
      }
      .mat-checkbox-background {
        background-color: unset;
      }
      .mat-checkbox-checkmark-path {
        stroke: $blue !important;
      }
    }
  }
  .add-number {
    position: relative;
    bottom: 48px;
    left: calc(30% + 37px);
    cursor: pointer !important;
    @include base_text(10px, normal, #55bfe6, $line-height: 1.3);
    visibility: visible;
    &.invisible {
      visibility: hidden;
    }
  }
  .cellphone {
    background-color: rgba($color: $blue, $alpha: 0.15);
    border-radius: 11px;
    @include base_text(11px, 300, $avva-dark-blue);
    padding: 0.5em 2em 0.5em 1em;
    margin-right: 1%;
    .close-button {
      cursor: pointer !important;
      position: relative;
      left: 1.3em;
      top: -0.4em;
    }
    &:hover {
      background-color: rgba($color: $blue, $alpha: 0.45);
    }
  }

  mat-form-field {
    &.form-field {
      width: 30%;
      @include base_text(12px, normal, $greyish);
      border-radius: 6px;
      margin-left: 25px;
    }
    .mat-form-field-appearance-fill.mat-form-field-flex {
      padding: unset !important;
    }
    .mat-form-field-underline {
      bottom: unset;
    }
  }
}

/*Styling do scrollbar da tela de analítico*/
div.card-cards-groups {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background: $gray;
    opacity: 0.8;
    &:hover {
      // background: #219bec;
      opacity: 1;
    }
  }
}

//Styling do radio button de configuração de analítico de máscara
.radio-button-mask {
  .mat-radio-inner-circle {
    background-color: $primary-blue !important;
  }
  &.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.65) !important;
  }
  &.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary-blue !important;
  }
  &.mat-radio-checked label {
    color: $dark-blue !important;
  }
}

//Styling do checkbox de deletar alertas
.checkbox_for_delete_alert {
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $blue !important;
  }
  .mat-checkbox-frame {
    border-color: $avva-darkest-blue !important;
  }
  .mat-checkbox-checkmark-path {
    stroke: white !important;
  }
  .mat-checkbox-inner-container {
    height: 20px !important;
    width: 20px !important;
  }
}

//Styling do sweet alert do vídeo do alerta
.alert-popup {
  background-color: rgba(33, 27, 29, 0.43) !important;
}

//classes modal alarmes
.select-modal-backgound .mat-mdc-dialog-container {
  padding: 0 !important;
  // image-set(
  //     url("assets/img/conta-2.png") 1x,
  //     url("assets/img/conta-2@2x.png") 2x,
  //     url("assets/img/conta-2@3x.png") 3x
  // );
  background-size: cover;
  background: url('../../assets/img/imagemmarketing_habilitaranalitico.png') top center;
}

//CLASSES MODAL ANALÍTICOS
.custom-modalbox-analytics .mat-mdc-dialog-container {
  padding: 0 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 4px #00000030;
  border-radius: 10px;
  opacity: 1;
}

.create-modalbox-analytics .mat-mdc-dialog-container {
  padding: 0 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 4px #00000030;
  border-radius: 10px;
  opacity: 1;
}

.create-modalbox-analytics .mat-mdc-dialog-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  left: -5px;
}

.create-modalbox-analytics .mat-mdc-dialog-container::-webkit-scrollbar-thumb {
  background: $avva-darkest-blue; 
  border-radius: 10px;
}

.create-modalbox-analytics .mat-mdc-dialog-container::-webkit-scrollbar-track {
  background-color: $greyish;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.custom-modalbox-analytics-list {
  @include scrool-blue();
  max-width: none !important;
}

//CLASSES MODAL ALARMES
.alarm-modal-background {
  height: fit-content;
  @include for-size(desktop-medium) {
    width: 45%;
  }
  @include for-size(tablet-portrait-landscape-desktop) {
    width: 80%;
  }
}

//CLASSES MODAL CONFIGURAÇÃO DE ALARMES
.section-scss {
  &.alarm-checkbox {
    min-height: 70px;
    border: 0.5px solid #d4d4d4;
    border-radius: 10px;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.19);
    @include ellipsis(2);
    &.checked {
      border-color: $blue;
    }
  }
  margin-top: 3%;
  &.big-gap {
    margin-top: 12%;
  }
  margin-bottom: 3%;
  .checkbox-footer {
    @include base_text(12px, normal, $greyish, $line-height: 1.08);
    padding-right: 4%;
    white-space: initial;
    @include for-size(desktop-medium) {
      width: calc(100vw * 0.4 - 67px);
    }
    @include for-size(tablet-portrait-landscape-desktop) {
      width: calc(100vw * 0.7 - 67px);
    }
  }
  mat-checkbox {
    position: relative;
    left: 25px;
    top: 20px;
    .mat-checkbox-frame {
      width: 20px !important;
      height: 20px !important;
      border-radius: 3px !important;
      border-width: 1px !important;
    }
    .mat-checkbox-label {
      margin-left: 2% !important;
      @include base_text(18px, normal, #757575, $line-height: 1.22);
    }
    .mat-checkbox-inner-container {
      height: 20px;
      width: 20px;
      margin-bottom: 3%;
    }
    .mat-checkbox-layout {
      margin-bottom: 5%;
    }

    &.mat-checkbox-checked {
      .mat-checkbox-frame {
        border-color: $dark-blue !important;
      }
      .mat-checkbox-label {
        margin-left: 2%;
        @include base_text(18px, normal, $avva-darkest-blue, $line-height: 1.22);
      }
      .mat-checkbox-background {
        background-color: unset;
      }
      .mat-checkbox-checkmark-path {
        stroke: $blue !important;
      }
    }
  }
  .add-number {
    position: relative;
    bottom: 48px;
    left: calc(30% + 37px);
    cursor: pointer !important;
    @include base_text(10px, normal, #55bfe6, $line-height: 1.3);
    visibility: visible;
    &.invisible {
      visibility: hidden;
    }
  }
  .cellphone {
    background-color: rgba($color: $blue, $alpha: 0.15);
    border-radius: 11px;
    @include base_text(11px, 300, $avva-dark-blue);
    padding: 0.5em 2em 0.5em 1em;
    margin-right: 1%;
    .close-button {
      cursor: pointer !important;
      position: relative;
      left: 1.3em;
      top: -0.4em;
    }
    &:hover {
      background-color: rgba($color: $blue, $alpha: 0.45);
    }
  }

  mat-form-field {
    &.form-field {
      width: 30%;
      @include base_text(12px, normal, $greyish);
      border-radius: 6px;
      margin-left: 25px;
    }
    .mat-form-field-appearance-fill.mat-form-field-flex {
      padding: unset !important;
    }
    .mat-form-field-underline {
      bottom: unset;
    }
  }
}

.radioButtonAnalytic .mat-radio-outer-circle {
  border-color: $primary-blue !important;
}

.radioButtonAnalytic .mat-radio-inner-circle {
  background: $primary-blue !important;
}

.radioButtonAnalytic .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary-blue !important;
}

.radioButtonAnalytic .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  border-color: $primary-blue !important;
}
