@mixin for-size($size) {
  // Modal de Convidados
  @if $size == container-invite-small {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $size == container-invite-mediun {
    @media (max-width: 1049px) {
      @content;
    }
  } @else if $size == container-invite-big {
    @media (min-width: 1050px) and (max-width: 1399px) {
      @content;
    }
  }
}

//MODAL ADICIONAR INFO AO GRUPO
.add-info-modal {
  width: 100%;
  background-color: red;
  height: fit-content;

  .mat-mdc-dialog-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 23px -1px rgba(107, 107, 107, 0.32);
    border-radius: 10px;
  }
}

//CLASSES MODAL CONVIDADOS
.guest-dialog-container {
  @include for-size(container-invite-mediun) {
    width: 100%;
  }
  @include for-size(container-invite-small) {
    width: 75%;
  }
  @include for-size(container-invite-big) {
    width: 80%;
  }
  height: fit-content;

  .mat-mdc-dialog-container {
    width: 944px !important;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 23px -1px rgba(107, 107, 107, 0.32);
    border-radius: 10px;
  }
}
/*Styling do scrollbar da tela de convidados*/
div.guest-table {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background: $blue;
    opacity: 0.8;
    &:hover {
      // background: #219bec;
      opacity: 1;
    }
  }
}

// Cor e estio da linha do dropdown do menu que separa a option configurações
.example-panel-black {
  mat-option:first-child {
    top: 6px;
  }

  border-radius: 4%;

}

// Cor e estio da linha do dropdown do menu que separa a option configurações
.example-panel-black.mat-select-panel {
  background: rgba(0, 0, 0, 0.8) !important;
  max-height: unset !important;
  overflow: unset !important;
  scrollbar-width: none !important;
}
// Cor e estio da linha do dropdown do menu que separa a option configurações
.example-panel-black .mat-option-text {
  color: $whitish !important;
}

//modifica o mat-select de velocidade do recuperar video
.panel-black-retrieve-videos {
  mat-option:first-child {
    top: 6px;
  }
  border-radius: 4%;
}

//modifica o mat-select de velocidade do recuperar video
.panel-black-retrieve-videos.mat-select-panel {
  background: rgba(0, 0, 0, 0.8) !important;
  max-height: unset !important;
  overflow: unset !important;
  scrollbar-width: none !important;
  margin-top: calc(100% - 300px);
}
//modifica o mat-select de velocidade do recuperar video
.panel-black-retrieve-videos .mat-option-text {
  color: $whitish !important;
}

//CLASSES MODAL EDIÇÃO DE CÂMERA
.edit-dialog-container {
  width: 80vw;
  height: fit-content;
}

//Classe modal de criação de mosaico
.mosaic-modal-container {
  width: 80vw;
  height: fit-content;
  max-height: none
}

.radio-button-plan {
  margin-top: 30px;
  .mat-radio-inner-circle {
    background-color: $blue !important;
  }
  &.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.65) !important;
  }
  &.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $blue !important;
  }
  &.mat-radio-checked label {
    color: black !important;
  }
}

.removeLineDatetime .no-line .mat-form-field-underline {
  display: none;
}

//CLASSES MODAL CONVIDADOS
.camera-list-dialog-container {
  @include for-size(container-invite-mediun) {
    width: 88%;
  }
  @include for-size(container-invite-small) {
    width: 45%;
  }
  @include for-size(container-invite-big) {
    width: 80%;
  }
  height: fit-content;

  .mat-mdc-dialog-container {
    width: 76%;
    min-width: 466px;
    margin: auto;
  }

  ::-webkit-scrollbar {
    width: 5px;
    left: -5px;
  }

  ::-webkit-scrollbar-thumb {
    background: $primary-blue; 
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #c3c3c3;
    border: 1.5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
}

.font_address_title .mat-radio-label-content{
  font-family: 'Open Sans', serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #414141;
}

.radioButtonCam .mat-radio-outer-circle{
  border-color:$primary-blue !important;
}

.radioButtonCam .mat-radio-inner-circle{
  background: $primary-blue !important;
}

.radioButtonCam .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: $primary-blue !important;
}

.radioButtonCam .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle{
  border-color: $primary-blue !important;
}
