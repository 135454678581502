// @import '@angular/material/prebuilt-themes/indigo-pink.css';

/* You can add global styles to this file, and also import other style files */
// @import "~nouislider/distribute/nouislider.min.css";
$whitish: #ffffff;
$background-gray: #efefef;
$avva-lighter-gray: #f6f6f6;
$avva-lighter-gray-active: #fbfbfb;
$avva-light-gray: $avva-lighter-gray;
$black: #414141;
$black-noir: #000000;
$black-shadow: #00000042;
$darkest-gray: #757575;
$dark-gray: #a0a9ab;
$soft-gray: #fcfcfc;
$gray: #c5c9d1;
$avva-soft-gray: #9ca7aa;
$avva-dark-gray: #979797;
$greyish: #aaaaaa;
$card-gray: #f9f9f9;
$font-view-gray: #848484;
$dark-blue: #002D59;
$blue: #326ABC;
$light-blue: #E9F2FF;
$faded-blue: #e2f6fb;
$dark-gold: #e8b700;
$gold: #ffd126;
$yellow-shine: #fff700;
$green-lemon: #11ff00;
$confirm-green: #4caf50;
$faded-green: #99d9b0;
$avva-soft-blue: #326ABC;
$avva-navy-blue: #6187a4;
$avva-darkest-blue: #326ABC;
$avva-dark-blue: #3c4858;
$avva-baby-blue: #7eb7c6;
$primary-blue: var(--primary-blue);
$secondary-blue: var(--secondary-blue);
$third-blue: var(--third-blue);
$fourth-blue: var(--fourth-blue);
$fifth-blue: var(--fifth-blue);
$seventh-blue: var(--seventh-blue);
$light-blue: var(--light-blue);
$purple-gradient: var(--purple-gradient);
$blue-gradient: var(--blue-gradient);
$secondary-gradient: var(--secondary-gradient);
$gradient-login: var(--login-gradient-initial) 5.73%, var(--login-gradient-final) 77.33%;
$success-green: var(--success-green);
$error-red: var(--error-red);
$medium-yellow: var(--medium-yellow);
$low-green: var(--low-green);

.wesafer {
  --primary-blue: #306EBF;
  --secondary-blue: #6CACFF;
  --third-blue: #F3F9FF;
  --fourth-blue: rgba(24, 144, 255, 0.15);
  --fifth-blue: #C0E0FF33;
  --light-blue: #C0E0FF;
  --dark-blue: #002D59;
  --success-green: #59EE4E;
  --error-red: #F0896F;
  --medium-yellow: #FFCB69;
  --low-green: #47E03C;
  --purple-gradient: linear-gradient(180deg, #722ED1 0%, #E3B8FF 100%);
  --blue-gradient: linear-gradient(180deg, #1890FF 0%, #C0E0FF 100%);
  --secondary-gradient: linear-gradient(180deg, #326ABC 0%, #1890FF 100%);
  --login-gradient-initial: #6CACFF;
  --login-gradient-final: #F0F9FF;
  --mat-datepicker-calendar-date-hover-state-background-color: #6CACFF;
  --mat-icon-button-state-layer-color: transparent;
  --mat-icon-button-disabled-state-layer-color: transparent;
}

.nextvision {
  --primary-blue: #21CAEC;
  --secondary-blue: #9EEFFF;
  --third-blue: #F3F9FF;
  --fourth-blue: rgba(24, 144, 255, 0.15);
  --fifth-blue: #D7F9FF;
  --light-blue: #EEFCFF;
  --dark-blue: #003C49;
  --success-green: #59EE4E;
  --error-red: #F0896F;
  --medium-yellow: #FFCB69;
  --low-green: #47E03C;
  --purple-gradient: linear-gradient(180deg, #722ED1 0%, #E3B8FF 100%);
  --blue-gradient: linear-gradient(180deg, #1890FF 0%, #C0E0FF 100%);
  --secondary-gradient: linear-gradient(180deg, #326ABC 0%, #1890FF 100%);
  --login-gradient-initial: #A5F0FF;
  --login-gradient-final: #EBFCFF;
}

// Analytics
@import "./SharedComponents/Analytics/analytics";
// Câmeras
@import "./SharedComponents/Cameras/cameras";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

//mixins
@mixin base_text($size, $weight, $color, $text-align: left, $line-height: 1) {
  font-family: 'Open Sans', serif !important;
  font-size: $size !important;
  font-weight: $weight !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: $line-height !important;
  letter-spacing: normal !important;
  text-align: $text-align !important;
  color: $color !important;
}

//Mixin para cortar o texto após x linhas
@mixin ellipsis($lines) {
  overflow: hidden;
  text-overflow: ellipsis !important;
  /* autoprefixer: off */
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: $lines !important;
}

//Mixin para formatar colunas de mat-tables
@mixin mat_column($flex-size) {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 $flex-size !important;
  width: $flex-size !important;
  overflow-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// Mixins para validar responsividade
@mixin for-size($size) {

  // Para micro-mobiles
  @if $size ==tablet-micro-mobile {
    @media (max-width: 500px) {
      @content;
    }
  }

  // Para mobile
  @else if $size ==for-phone {
    @media (max-width: 599px) {
      @content;
    }
  }

  // Para tablets pequenos
  @else if $size ==tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  }

  // Para tablets e pequenos desktops e desktops
  @else if $size ==tablet-portrait-landscape-desktop-medium {
    @media (min-width: 600px) and (max-width: 959px) {
      @content;
    }
  }

  // Para tablets e pequenos desktops e desktops
  @else if $size ==tablet-portrait-landscape-desktop {
    @media (min-width: 600px) and (max-width: 1279px) {
      @content;
    }
  }

  @else if $size ==tablet-portrait-landscape {
    @media (max-width: 800px) {
      @content;
    }
  }

  // Para tablets e pequenos desktops
  @else if $size ==tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  }

  // Para tablets e medianos desktops
  @else if $size ==tablet-landscape-medium {
    @media (min-width: 950px) {
      @content;
    }
  }

  // Para tablets e medianos desktops
  @else if $size ==tablet-landscape-big {
    @media (min-width: 990px) {
      @content;
    }
  }

  // Para desktops
  @else if $size ==desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  }

  // Para desktops medianos
  @else if $size ==desktop-medium {
    @media (min-width: 1280px) {
      @content;
    }
  }

  // Para desktops grandes
  @else if $size ==big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }

  // Para desktops e tvs
  @else if $size ==big-desktop-tv {
    @media (min-width: 2560px) {
      @content;
    }
  }

  // Mixins de MediasQueryes especificos para algumas telas
  // table camera list
  @else if $size ==table-camera-medium {
    @media (max-width: 959px) {
      @content;
    }
  }

  // Para CameraView - Mosaico
  @else if $size ==micro-macro-mobile {
    @media (max-width: 768px) and (min-width: 450px) {
      @content;
    }
  }

  @else if $size ==big-mobile-desktop {
    @media (min-width: 1260px) and (max-width: 1500px) {
      @content;
    }
  }

  @else if $size ==tablet-portrait-for-mosaic {
    @media (min-width: 961px) and (max-width: 1259px) {
      @content;
    }
  }

  @else if $size ==screenshot-small {
    @media (max-width: 960px) {
      @content;
    }
  }

  @else if $size ==screenshot-medium {
    @media (min-width: 961px) and (max-width: 1259px) {
      @content;
    }
  }

  @else if $size ==screenshot-big {
    @media (min-width: 1260px) and (max-width: 1500px) {
      @content;
    }
  }

  // Para CameraPlaylist - lista de cameras
  @else if $size ==landscape-for-playlist {
    @media (max-width: 1199px) {
      @content;
    }
  }

  @else if $size ==tablet-landscape {
    @media (min-width: 960px) and (max-width: 1100px) {
      @content;
    }
  }

  @else if $size ==mobile-tablet-landscape {
    @media (min-width: 600px) and (max-width: 1919px) {
      @content;
    }
  }

  @else if $size ==landscape-tv {
    @media (min-width: 960px) and (max-width: 1919px) {
      @content;
    }
  }

  @else if $size ==tv-big-tv {
    @media (min-width: 1100px) and (max-width: 2559px) {
      @content;
    }
  }

  @else if $size ==big-landscape {
    @media (min-width: 1200px) and (max-width: 1919px) {
      @content;
    }
  }

  @else if $size ==card-size {
    @media (min-width: 1920px) and (max-width: 2559px) {
      @content;
    }
  }

  // Input login style
  @else if $size ==input-for-login {
    @media (max-width: 959px) {
      @content;
    }
  }
}

// Mixins para validar o estilo do scrool
@mixin scrool-blue() {
  ::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    left: -5px;
  }

  ::-webkit-scrollbar-thumb {
    background: $avva-darkest-blue !important;
    border-radius: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: $greyish;
    border: 3px solid transparent !important;
    border-radius: 9px !important;
    background-clip: content-box !important;
  }
}

// Mixins para validar o estilo do scrool
@mixin horizontal-scroll() {
  overflow-y: hidden !important;
  overflow-x: unset !important;
}

//CLASSES MODAL EDIÇÃO DE CÂMERA
.edit-dialog-modal-container {
  width: 70vw;
  height: 90vh;
}

::ng-deep .mat-mdc-dialog-container {
  .mdc-dialog__surface {
    box-shadow: 2px 4px 12px 0px rgba(24, 144, 255, 0.48) !important;
    border-radius: 12px !important;
    overflow: hidden;
    height: fit-content;
  }
}

// Background cinza usado em vários containers
.background-globals {
  background-color: $third-blue;
  padding-top: 0.5%;
}

// Único caso aceitável de NG-DEEP para sobrescrever  tooltip
::ng-deep .mat-tooltip {
  font-size: 15px;
  background: $soft-gray;
  color: $black !important;
}

//Global classes
.modal-backdrop {
  background-color: red;
}

.clickable {
  cursor: pointer;
}

//BOTÃO EXTRA PEQUENO NO PADRÃO BOOTSTRAP
.btn-group-xs>.btn,
.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

//ESTILO PARA BOTOES DO SWAL
.swal2-styled {
  padding: 0 !important;
}

//CLASSES MODAL EDIÇÃO DE CÂMERA (Manter no style geral, pois a lib do modal reconhece apenas no style geral)

input[type="time"]::-webkit-clear-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.camera-body {
  overflow-y: scroll;
  @include scrool-blue();
  @include horizontal-scroll();
}

.camera-card {
  height: 20vw;
}

.progress {
  height: 26px;
  box-shadow: none;
  margin-bottom: 0;
  display: unset;
  overflow: unset;
  font-size: unset;
  background: unset none;
  border-radius: unset;
}

.hide-mouse {
  cursor: default;
}

.popover-title {
  color: $avva-soft-blue;
  background-color: #e5e5e5;
}

.customDropdown {
  margin-top: 30% !important;

  .mat-option:hover {
    background-color: $primary-blue !important;
  }

  .mat-option {
    background-color: transparent !important;
  }
}

.mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: unset;
}

.mat-slide-toggle-bar {
  background-color: red;
}

.mat-slide-toggle-thumb {
  background-color: red;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: rgb(48, 240, 48);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(48, 240, 48);
}

mat-progress-bar .mat-progress-bar-buffer {
  background: #E4E8EB;
}

.ngx-timepicker-control__input {
  @include base_text(30px, 300, #333333);
}

.ant-picker-input>input {
  text-align: center !important;
  color: $dark-blue !important;
  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: $secondary-blue !important;
    font-size: 14px;
    font-weight: 400;
  }
}

.ant-picker-suffix {
  display: flex;
  font-size: 16px;
  color: $primary-blue !important;
}

.ant-picker-range .ant-picker-clear {
  display: flex;
  font-size: 16px;
}

.ant-picker-separator {
  display: flex !important;
  color: $secondary-blue !important;
}

.datepicker {
  font-size: xx-large;
  font-weight: inherit;

  .date-input {
    @include base_text(20px, 300, black, $line-height: 1.5);
  }

  &#dateBegin {
    .mat-form-field-underline {
      margin-bottom: 5% !important;
    }
  }

  &#dateEnd {
    .mat-form-field-underline {
      margin-bottom: 5% !important;
    }
  }
}

.fileinput .thumbnail>img {
  &.img-fade {
    opacity: 1;
    display: block !important;
    width: 100% !important;
    height: auto !important;
    transition: 0.5s ease !important;
    backface-visibility: hidden !important;

    &:hover {
      opacity: 0.3 !important;
    }
  }
}

.fileinput .fileinput-preview {
  &:hover {
    img {
      opacity: 0.3;
    }

    .overlay {
      opacity: 1 !important;
      z-index: 999;
      cursor: pointer;
    }
  }
}

.fileinput {
  .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 20%;
    left: calc(20vw - 3%);

    @include for-size(input-for-login) {
      top: 17%;
      left: calc(2vw + 105px);
    }

    @include for-size(for-phone) {
      left: 105px;
    }

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
}

//Styling do mat-card
mat-card {
  &.no-shadow {
    box-shadow: none !important;
  }
}

/*Styling do scrollbar da tela de analítico*/
div.card-cards-groups {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background: #c4c9ca;
    opacity: 0.8;

    &:hover {
      // background: #219bec;
      opacity: 1;
    }
  }
}

//Styling do popover na aba de conexão de rede
.health-popover {
  max-width: unset !important;
  width: 75%;
}

.popover-arrow {
  display: none !important;
}

//Styling do scrolling do histórico de conexão
div.infinite-scroll-container {
  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background: $primary-blue;
    opacity: 0.8;

    &:hover {
      // background: #219bec;
      opacity: 1;
    }
  }
}

//Styling do radio button de configuração de analítico de máscara
.radio-button-mask {
  color: $primary-blue;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.ant-radio-inner:after {
  background-color: $primary-blue !important;
}

.ant-radio-inner {
  border-color: $primary-blue !important;
}

//Styling da tag de grupo de analítico
.ng2-tag-input.filled-theme tag {
  background-color: $primary-blue !important;
  margin: 0 !important;
  //padding: 0.08rem 0.45rem !important;
}

//Styling do checkbox de deletar alertas
.checkbox_for_delete_alert {
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $primary-blue !important;
  }

  .mat-checkbox-frame {
    border-color: $primary-blue !important;
  }

  .mat-checkbox-checkmark-path {
    stroke: white !important;
  }

  .mat-checkbox-inner-container {
    height: 20px !important;
    width: 20px !important;
  }
}

//Styling do sweet alert do vídeo do alerta
.alert-popup {
  background-color: rgba(33, 27, 29, 0.43) !important;
}

.alert-container {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.alert-icon {
  font-size: 52px;
}

.alert-eval {
  border: solid 1px #ffee00;
  color: #ffee00;
  background-color: rgba(0, 0, 0, 0.14);

  &:hover {
    border: solid 1px #e4d615;
    color: #e4d615;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.eval-button {
  border: solid 1px #ffee00;
  color: white;
  background-color: #807700;
  pointer-events: none;
}

p.counter {
  color: white;
  font-size: 12px;
  text-align: right;
  margin-top: -4%;
  margin-right: 3%;
}

input.alert-comment {
  background-color: rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  border: solid 0.001px black;
  font-size: 24px;
  color: white;
  width: 100%;
  height: 3.5em;
}

//CLASSES MODAL EDIÇÃO DE CÂMERA
.edit-dialog-container {
  width: 70vw !important;
  height: fit-content !important;
}

/*----STYLING DO BOTÃO DE TOGGLE PERSONALIZADO----*/
.onoffswitch {
  position: relative;
  width: 90px;

  @include for-size(for-phone) {
    width: 60px;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  width: 75%;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: $primary-blue;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #ff0000;
  color: #ffffff;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  // margin: 6px;
  margin-right: 30px;
  margin-bottom: 7px;
  margin-top: 7px;
  margin-left: 7px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  //right: 56px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/*TOGGLE PEQUENO*/
.onoffswitch-small {
  position: relative;
  width: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.permission_for_select .mat-form-field-underline {
  display: none !important;
}

.select_for_permission .mat-select-arrow {
  color: transparent !important;
}

.checkbox_for_permission_select .mat-checkbox-frame {
  border-color: transparent !important;
}

.checkbox_for_permission_select .mat-checkbox-checkmark-path {
  stroke: #757575 !important;
}

.checkbox_for_permission_select .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: transparent !important;
}

.checkbox_for_permission_select .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent !important;
}

.checkbox_for_permission_select .mat-checkbox-label {
  color: #414141 !important;
}

.checkbox_for_delete_invite .mat-checkbox-frame {
  border-color: #757575 !important;
}

.checkbox_for_delete_invite .mat-checkbox-checkmark-path {
  stroke: #757575 !important;
}

.title_report .mat-card-header-text {
  margin: 0 0px !important;
}

.remove_mat_pseudo_checkbox_for_invite .mat-pseudo-checkbox {
  display: none !important;
}

.text_tab_style .mat-tab-label-content {
  text-transform: uppercase !important;
  color: $primary-blue !important;
  text-align: center !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.2 !important;
  letter-spacing: normal !important;
  font-weight: 200;
}

.text_tab_style .mat-tab-label-container {
  background-color: $avva-lighter-gray !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.remove_max_width .mat-select-value {
  max-width: 100%;
}

.selected_list .mat-option.mat-selected:not(.mat-option-multiple) {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background: $primary-blue !important;
  color: #fff !important;
}

.change_overflow_tabs .mat-tab-body-content {
  overflow: unset !important;
}

.change_space_mat_tab .mat-tab-group {
  margin-left: -16px;
}

.container_without_cameras {
  background-color: #f6f6f6;

  .without_cams_col {
    margin: auto;
  }

  .without_cams_row {
    justify-content: center;
  }

  .container_register {
    margin-top: 5%;
  }
}

.custom-modalbox-analytics {
  overflow: auto !important;
}

.mat-mdc-dialog-container {
  height: inherit !important;
}

//CLASSES MODAL ANALÍTICOS
.create-modalbox-analytics {
  max-width: 80vw !important;
  .mat-mdc-dialog-container {
    @include horizontal-scroll();
  }
}

//CLASSES MODAL ANALÍTICOS
.custom-modalbox-analytics-list .mat-mdc-dialog-container {
  @include horizontal-scroll();
}

//MODAL VIDEO CUSTOMIZADO
.modal-background .mat-mdc-dialog-container {
  background: transparent !important;
  box-shadow: none !important;
}

//COMPONENTE DE MODAL
.modal-component {
  display: flex;
  align-items: center;

  .mat-mdc-dialog-container {
    box-shadow: 2px 4px 12px rgba(24, 144, 255, 0.18);
    border-radius: 12px;
  }
}

.lightBackdrop {
  background-color: rgba(255, 255, 255, 0.6);
}

// Customiza o background do MatDialog caso seja definido nas options
.backdropDark {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.swalDark {
  background-color: rgba(33, 27, 29, 0.8) !important;
}

// Customiza o pagination de histórico de alertas
.customize_pagination {
  .mat-paginator-range-label {
    display: none !important;
  }

  .mat-paginator-page-size .ng-star-inserted {
    color: #3f51b5 !important;
    display: none !important;
  }

  .mat-paginator-page-size-label {
    display: none !important;
  }
}

.input_number_remove_arrows {
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

@media only screen and (max-width: 599px) {
  div {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 958px) {
  .example-container.mat-elevation-z8 {
    overflow-x: auto;
    @include scrool-blue();
  }

  .example-container .mat-table.mat-table {
    min-width: 450px;
  }

  .mat-row,
  .mat-header-row {
    min-width: 450px;
    width: 100%;
  }

  .responsive-menu-alert {
    display: grid !important;
    grid-gap: 20px 20px;
    grid-template-columns: auto !important;
  }

  .responsive-menu-alert:nth-child(1) {
    max-width: 100%;
  }

  div {
    max-width: 100% !important;
  }

  .filter-responsive {
    margin-left: 32px;
  }

  #filter-desktop {
    display: none;
  }

  #filter-mobile {
    display: block;
  }
}

@media only screen and (min-width: 959px) and (max-width: 1278px) {
  .responsive-menu-alert {
    display: grid !important;
    grid-gap: 20px 20px;
    grid-template-columns: auto auto !important;

    div {
      max-width: 100% !important;
    }

    div:nth-child(3) {
      margin: 2% 6% !important;
    }

    .example-container.mat-elevation-z8 {
      overflow-x: auto;
      @include scrool-blue();
    }

    .example-container .mat-table.mat-table {
      min-width: 900px;
      max-width: 1278px;
    }

    .mat-row,
    .mat-header-row {
      min-width: 900px;
      width: 100%;
    }
  }

  #filter-desktop {
    display: none;
  }

  #filter-mobile {
    display: block;
  }
}

@media only screen and (min-width: 1279px) {
  .responsive-menu-alert {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    margin: 0px -35px -35px 0px;
    place-content: center flex-start;
    align-items: center;
    grid-gap: 15px 15px;
  }

  .filter-responsive {
    margin-left: 10px;
  }

  #filter-desktop {
    display: block;
  }

  #filter-mobile {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 4px !important;
  left: -5px !important;
}

::-webkit-scrollbar-thumb {
  background: $primary-blue !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-track {
  background-color: $greyish;
  border: 3px solid transparent !important;
  border-radius: 9px !important;
  background-clip: content-box !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.mat-selected {
  span {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.loading {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 48%;
  left: 48%;
}

span circle {
  stroke: $primary-blue;
}

span,
p {
  font-family: 'Open Sans', serif !important;
}

::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: $primary-blue !important;
}

::ng-deep.mat-form-field-underline {
  /*change color of underline*/
  color: $primary-blue !important;
}

::ng-deep.mat-form-field-ripple {
  /*change color of underline when focused*/
  caret-color: $primary-blue !important;
  background-color: $primary-blue !important;
}

::ng-deep.mat-form-field.mat-form-field-invalid .mat-form-field-ripple .mat-form-field-underline {
  /*change color of underline when focused*/
  caret-color: #ff1744 !important;
  background-color: #ff1744 !important;
}

.mat-calendar-body-selected,
.mat-calendar-body-selected:hover {
  background-color: $primary-blue !important;
  color: white !important;
}

.ant-input-number-handler-wrap {
  opacity: unset !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.ant-switch-checked {
  background-color: $primary-blue !important;
}

.tooltip-delete-client {
  .ant-popover-inner-content {
    width: 320px !important;
    height: 120px !important;
    padding-left: 5px;
    background-color: #FFFFFF;
    color: #6B6B6B;
    text-align: center;
    padding: 10px !important;
    font-size: 12px;
    margin-left: -209px;
    margin-top: -120px;

    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
  }
}

.popover-container {
  .ant-popover-inner {
    border-radius: 10px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
  }

  .ant-popover-arrow {
    display: none;
  }
}

.popover-container-view {
  height: 200px;

  .ant-popover-inner {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 12px 25px;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.tooltip-container {
  width: 410px;
  align-items: center;
  justify-content: center;
  margin: 18px 10px 10px;

  .ant-popover-inner {
    background: #FFFFFF;
    box-shadow: 2px 4px 12px rgba(24, 144, 255, 0.18);
    border-radius: 12px;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.tooltip-partner {
  width: 600px;
  align-items: center;

  .ant-popover-inner {
    background: #FFFFFF;
    box-shadow: 2px 4px 12px rgba(24, 144, 255, 0.18);
    border-radius: 12px;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 25px 1px;
  }
}

.antd-table {
  min-height: 200px;
}

.antd-table {

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled a {
    color: $fifth-blue;
  }

  .ant-pagination-item-active,
  .ant-pagination-item,
  .ant-pagination.mini .ant-pagination-item {
    border-color: transparent;
  }

  .ant-pagination-next a,
  .ant-pagination-prev a {
    color: $dark-blue;
  }

  .ant-pagination-item-active a {
    width: 24px;
    height: 24px;
    font-size: 14px;
    display: flex;
    font-family: 'Open Sans';
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    background: #F3F9FF;
    border: 1px solid $primary-blue;
    font-weight: 600 !important;
    color: $primary-blue !important;
  }

  .ant-pagination-item a {
    line-height: 25px;
    color: $dark-blue;
    font-weight: 400;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 14px;
  }
}

.antd-table tbody {
  tr:nth-child(odd) {
    background-color: $fifth-blue;
    border-collapse: separate;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.antd-table .ant-table-placeholder {
  display: none;
}

.table>:not(caption)>*>* {
  border-bottom-width: 0;
}

.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  align-items: center;
  color: $primary-blue;
}

/* Font color */
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  color: $primary-blue;
}

// mat-label focused style
::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: $primary-blue !important;
}

// mat-label normal state style
::ng-deep .mat-form-field-label {
  color: $primary-blue !important;
}

//mat-input focused color
::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $primary-blue !important;
  caret-color: $primary-blue !important;
}

// mat-input current color
::ng-deep .mat-input-element {
  caret-color: $primary-blue!important;
}

.btn-primary:not(:disabled):not(.disabled):active{
  background-color: $primary-blue;
  border-color: $primary-blue;
}

.btn.btn-primary:focus, .btn.btn-primary:hover{
  background-color: $primary-blue;
  border-color: $primary-blue;
}

.swal2-styled.swal2-confirm {
  background-color: $primary-blue !important;
}

//Estilização do componente InputNumber do ant
.inputNumberCustom {
  .ant-input-number-handler-wrap {
    border-radius: 0px 10px 10px 0px;
    border-left: 1px solid $secondary-blue;
    background: transparent;
  }

  .ant-input-number-handler-down {
    border-top: 1px solid $secondary-blue;
  }

  i {
    color: $secondary-blue;
  }
}

.btn.btn-ant {
  border-radius: 10px;
  padding: 12px 35px;
  font-family: 'Open Sans', serif;
  font-weight: 700;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .prefix {
    margin-right: 10px;
    font-size: 16px;
  }

  .posfix {
    position: absolute;
    right: 5px;
    font-size: 16px;
  }

  &.btn-primary {
    background-color: $primary-blue;
    color: #ffffff;
  }

  &.btn-secondary {
    background-color: #ACACAC;
    color: #ffffff;
  }
}

.swal2-html-container {
  font-size: 16px;
}

.style-thumbnail {
  position: relative;
  width: 96px;
  height: 67px;
  border-radius: 5px;
  margin-left: 0px;

  .image-img {
    width: 100%;
    height: 67px;
    display: block;
    border-radius: 5px;
  }

  .image-overlay {
    background: rgba(7, 51, 93, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
  }

  .image-overlay:hover {
    opacity: 1;
  }
}

::ng-deep .cam-name .ant-tooltip {
  top: 12px !important;
  right: 25px !important;
}

.cam-name {
  .ant-tooltip-inner {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
    background-color: $dark-blue;
    padding: 8px 16px 8px 16px;
    gap: 10px;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }
}

.camera_playlist_detections {
  .ant-tooltip-inner {
    color: #FFFFFF;
    font-family: 'Open Sans', serif;
    font-size: 12px;
    border-radius: 8px;
    background-color: $dark-blue;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }
}

.camera_playlist_processing {
  .ant-tooltip-inner {
    color: $dark-blue;
    font-family: 'Open Sans';
    font-size: 12px;
    border-radius: 8px;
    background-color: #FFFFFF;
    text-align: center;
    font-weight: 400;
  }

  &.integrated {
    max-width: 220px !important;
  }

  &.rtsp {
    max-width: 300px !important;
  }

  &.dark-blue-tooltip {
    .ant-tooltip-inner {
      color: #FFFFFF;
      background-color: $dark-blue;
    }
  }

  .ant-tooltip-arrow {
    display: none !important;
  }
}

.camera-actived-date {
  .ant-tooltip-inner {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: $dark-blue;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 4px 12px rgba(24, 144, 255, 0.18);
    padding: 15px;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }
}

.camera-link-rtsp {
  .ant-tooltip-inner {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: $dark-blue;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 4px 12px rgba(24, 144, 255, 0.18);
    padding: 15px;
    width: 470px;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }
}

.private_group_info {
  z-index: 1000000;

  .ant-tooltip-inner {
    color: #FFFFFF;
    font-family: 'Open Sans', serif;
    text-align: center;
    font-size: 12px;
    line-height: 13px;
    border-radius: 8px;
    background-color: $dark-blue;
    width: 470px;
    height: 60px;
    padding: 10px;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }
}

::ng-deep.ant-checkbox-inner {
  border-color: $primary-blue !important;
}

span.ant-checkbox-checked .ant-checkbox-inner {
  // background-color: #1890ff;
  // border-color: #1890ff;
  background-color: $primary-blue !important;
  border: 1px solid $primary-blue !important;
  border-radius: 2px;
}

.camera_status_true {
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.32);
  background-color: $success-green;
  border-radius: 1.25rem;

  @include for-size(big-desktop-up) {
    width: 1.75rem !important;
    height: 1.75rem !important;
  }

  @include for-size(desktop-medium) {
    width: 1.25rem;
    height: 1.25rem;
  }

  @include for-size(tablet-portrait-landscape-desktop) {
    width: 1rem;
    height: 1rem;
  }

  @include for-size(for-phone) {
    width: 0.75rem;
    height: 0.75rem;
  }
}

// Classe que valida o status vermelho para câmera inativa
.camera_status_false {
  width: 1.75rem;
  height: 1.75rem;
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.32);
  background-color: $secondary-blue;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ícone de exclamação para câmera inativa
.camera_status_false:after {
  display: inline-block;
  text-align: center;
  font-weight: 900;
  font-size: 28px;
  color: $whitish;
}

.status-loading {
  color: white;
  position: relative;
  bottom: 4px;
  left: 2px;
}

.spin-mosaic {
  .ant-spin {
    max-height: none !important;
  }
}

.ant-spin-blur {
  opacity: 1 !important;
}

// Quando o status da câmera é nulo por que não há câmera no mosaico este campo não aparece
.camera_status_unknow {
  display: none;
}

.inventory {

  .ant-tooltip-inner {
    color: #FFFFFF;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    font-size: 13px;
    border-radius: 8px;
    background-color: $dark-blue;
    padding: 10px;
  }

  .ant-tooltip-arrow {
    display: none !important;
  }
}

.swalStyle {
  width: 35em !important;
  height: 20em !important;
  border-radius: 15px !important;
  box-shadow: 2px 4px 12px 0px rgba(24, 144, 255, 0.48);
}

.swalParcialFailure {
  width: 35em !important;
  height: 28em !important;
  border-radius: 15px !important;
  box-shadow: 2px 4px 12px 0px rgba(24, 144, 255, 0.48);
}

.swalTitle {
  color: rgba(7, 51, 93, 1);
  font-size: 25px !important;
  font-weight: 700;
  text-align: center;
}


.spin-live {
  height: 100%;

  .ant-spin-container {
    height: 100% !important;
  }
}

.ant-slider-dot-active {
  background-color: $primary-blue !important;
  border: 2px solid $primary-blue !important;
  width: 6px !important;
  height: 4px !important;
  top: 0 !important;
}

.ant-slider-handle {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  margin-top: -3px !important;
  border: 2px solid $primary-blue !important;
  background-color: $primary-blue !important;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  transition: border-color .3s, box-shadow .6s, transform .3s cubic-bezier(.18, .89, .32, 1.28);
}

.ant-slider-track {
  background-color: $primary-blue !important;
}

.slider-analytic {
  .ant-slider-with-marks {
    margin-bottom: 0 !important;
    top: 20px;
  }
}

.mat-option:hover {
  background-color: $primary-blue !important;
}

.speed_container {
  .ant-select-selector {
    background-color: transparent !important;
    border: 10px transparent !important;
    padding: 0 10px !important;
    height: fit-content !important;
  }

  .ant-select-arrow {
    color: white !important;
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0 !important;
}

.cdk-global-overlay-wrapper {
  background: #002D594D !important;
}

.mat-mdc-form-field-error {
  color: $error-red !important;
}

// camera-form steps

.ant-select-selection-placeholder {
  color: $secondary-blue !important;
}

.ant-select-item {
  color: $dark-blue !important;

  &.ant-select-item-option-selected {
    background-color: $light-blue !important;
  }

  &.ant-select-item-option-active {
    background-color: $third-blue !important;
  }
}

.ant-steps-item-title {
  font-size: 14px !important;
}

.ant-steps-item-wait .ant-steps-item-icon  {
  background-color: #F3F9FF !important;
  border-color: #C0E0FF !important;
  color: #C0E0FF !important;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: #C0E0FF !important;
  top: -2px !important;
}

.ant-steps-item-title:after  {
  background-color: $secondary-blue !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: $primary-blue !important;
  border: none !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: $secondary-blue !important;
  top: -2px !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title, .ant-switch {
  color: $dark-blue !important;
}

.ant-steps-item-icon {
  width: 24px !important;
  height: 24px !important;
  padding: 1px !important;
  font-size: 14px !important;
  line-height: 25px !important;
  margin: 3px 8px 0 0 !important;
  vertical-align: middle !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $dark-blue !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  top: -2px !important;
  color: $dark-blue !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-line-ripple {
  display: none;
}

.mat-datepicker-toggle {
  color: $secondary-blue !important;
}

.mat-mdc-icon-button:hover,
.mat-mdc-icon-button:active {
  background-color: $fifth-blue !important;
  color: $secondary-blue !important;
}

.mat-mdc-icon-button svg {
  width: 1.1em !important;
}

.mat-datepicker-toggle-default-icon {
  margin-top: -20% !important;
  margin-left: -20% !important;
}

.mat-button-toggle-focus-overlay {
  display: none !important;
}

.mat-calendar-body-selected {
  background-color: $light-blue;
}

.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  width: 200px;
  border: 1px solid $secondary-blue;
  border-radius: 10px;
  padding: 8px 12px;
  min-height: 0 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: transparent !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 42px !important;
  margin-left: 10px;
  background-color: $light-blue !important;
}

.mat-datepicker-content .mat-calendar-previous-button:not(.mat-mdc-button-disabled) {
  background-color: transparent !important;
}

.mat-datepicker-content .mat-calendar-next-button:not(.mat-mdc-button-disabled) {
  background-color: transparent !important;
}

.mat-focused .placeholder {
  color: $light-blue;
}

.mat-date-range-input-container {
  color: $dark-blue;
}


::ng-deep .mat-calendar-body-in-range::after {
  background-color: $light-blue !important;
  .mat-expansion-indicator::after {
    color: #306EBF !important;
    }
}

.leaflet-touch .leaflet-bar a {
  color: $dark-blue;
}

// mapa

.leaflet-popup-content {
  margin: 13px 8px 13px 8px !important;
  width: 220px !important;
}

.marker-cluster-small {
  background-color: rgba(48, 110, 191, 0.5)
}

.marker-cluster-small div {
  background-color: rgba(48, 110, 191, 0.5)
}

.marker-cluster-medium {
  background-color: rgba(48, 110, 191, 0.5)
}

.marker-cluster-medium div {
  background-color: rgba(48, 110, 191, 0.5)
}

.marker-cluster-large {
  background-color: rgba(48, 110, 191, 0.5)
}

.marker-cluster-large div {
  background-color: rgba(48, 110, 191, 0.5)
}

.leaflet-oldie .marker-cluster-small {
  background-color: rgba(48, 110, 191, 1)
}

.leaflet-oldie .marker-cluster-small div {
  background-color: rgba(48, 110, 191, 1)
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgba(48, 110, 191, 1)
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: rgba(48, 110, 191, 1)
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgba(48, 110, 191, 1)
}

.leaflet-oldie .marker-cluster-large div {
  background-color: rgba(48, 110, 191, 1)
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 23px;
  height: 23px;
  margin-left: 8px;
  margin-top: 8px;

  text-align: center;
  border-radius: 15px;
}

.marker-cluster span {
  line-height: 23px;
  color: white;
}


.leaflet-control-locate a .leaflet-control-locate-location-arrow {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;
  background-image: url('./assets/img/Icons/locatecontrol.svg') !important;
}

.leaflet-touch .leaflet-bar a {
  width: 40px !important;
  height: 38px !important;
  margin-left: 28px !important;
}

.leaflet-touch .leaflet-bar {
  border: none !important;
}

.leaflet-popup {
  bottom: 0px !important;
}

.mat-expansion-indicator::after {
  color: $primary-blue !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  box-shadow: 0 3px 10px #5899FF7A !important; 
}

::ng-deep .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background-color: transparent !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: $primary-blue !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after, .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  color: $primary-blue !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: rgba(24, 144, 255, 0.15) !important;
}

.mat-mdc-select-arrow {
  color: #999 !important;
}
