// Bootstrap brand color customization


/*     brand Colors              */
@import '../../../../styles.scss';

$brand-primary:              $blue;//$purple-500 !default;
$brand-info:                 $gray;//$cyan-500 !default;
$brand-success:              $green-500 !default;
$brand-warning:              $orange-500 !default;
$brand-danger:               $red-500 !default;
$brand-rose:                 $pink-500 !default;
$brand-inverse:              $black-color !default;
