@import '../styles.scss';

.main-content {
  background-color: $third-blue;
}

.request-paginator {
  .mat-paginator-page-size {
    opacity: 0;
  }
}

.main-content-style{
  background-color: $third-blue; 
  margin-top: -35px;
}

.center-spinner{
  color: $primary-blue!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-help {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 100px;
  z-index: 800;
  .inside {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $primary-blue;
    color: $third-blue;
    border: none;
    border-radius: 30px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    position: relative;
    padding: 0.7rem;

    i {
      font-size: 24px;
      margin-right: 7px;
    }
    
    &:hover {
      cursor: pointer;
      background-color: $secondary-blue;
    }
  }
}


.loadingCamera .main-panel span {
  color: $dark-blue;
  font-size: 60px;
}

.ant-popover-arrow {
  visibility: hidden !important;
}

.tooltiptext {
  justify-content: flex-end !important;
  max-width: 320px;
  box-shadow: 0 2px 6px 0 rgba(24, 144, 255, 0.48);
  border-radius: 12px;
  margin: 0 0 24px 0;
  background-color: $whitish;
  
  /* posição do tooltip em relação a div */
  position: absolute;
  bottom: 0;
  right: 24px;
  z-index: 21;
}

.component-route {
  min-height: 89%;
}

.copyright {
  margin: 0px !important;
  font-size: 14px;
  padding: 0px;

  font-weight: 700;
  color: rgba(48, 110, 191, 1);
  text-align: center;
  background-color: inherit;
}

.copyright-icon {
  font-size: 21px;
  margin-right: 5px;
}

@media screen and (max-width:800px) {
  .main-content-style{
    margin-top: -52px;
  }
}
